<template>
  <!--begin::PriceSearch step 1 form-->
  <div>
    <div
      class="bg-primary bgi-size-cover bgi-position-y-center bgi-position-x-center p-0"
      style="background-image: url(media/landing/pricesearch/cotizador.jpg); border: 1px solid #1b2e5a;"
    >
      <div class="home-title mt-0 pt-9">
        {{ $t("PRICE_SEARCH.STEP1.TITLE") }}
        <hr class="home-title-sub" />
      </div>

      <div class="mb-12 px-15">
        <div
          class="white-box p-9"
          style="width: fit-content !important; min-width: 50vw"
          @keydown.enter.prevent
        >
          <v-form ref="formStep1" lazy-validation class="form">
            <div class="row">
              <div
                class="form-group col-lg-12 mb-0 pl-9"
                style="margin-top: -1.5em;"
              >
                <v-radio-group
                  mandatory
                  row
                  v-model="Fields.Routes[CurrentRoute].Back.EnabledString"
                  @change="onChangeBackEnabled"
                >
                  <v-radio value="false" class="mr-6">
                    <template v-slot:label>
                      <div class="pt-2 pl-2">
                        {{ $t("PRICE_SEARCH.STEP1.JUST_THERE") }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="true" class="mr-6">
                    <template v-slot:label>
                      <div class="pt-2 pl-2">
                        {{ $t("PRICE_SEARCH.STEP1.THERE_AND_BACK") }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>

            <div class="row mb-3">
              <!--begin::OriginAddress-->
              <div class="form-group col-lg-6 px-9 mb-0">
                <div class="row">
                  <div class="col-lg-12">
                    <label>
                      <inline-svg
                        src="/media/icons/pin-1-primary.svg"
                        class="mr-3"
                      />
                      {{ $t("PRICE_SEARCH.STEP1.ORIGIN") }}
                    </label>
                  </div>

                  <div class="form-group col-lg-9">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.FULL_ADDRESS_2") }}
                    </label>
                    <VueGoogleAutocomplete
                      ref="autocompleteOriginAddress"
                      id="autocompleteOriginAddress"
                      country="es"
                      :fields="['address_components', 'geometry', 'id', 'name']"
                      v-model="Fields.Routes[CurrentRoute].OriginAddress.Street"
                      :rules="[rules.required]"
                      required
                      classname="form-control"
                      placeholder=""
                      @placechanged="onAutocompleteOriginPlaceChanged"
                      @inputChange="onAutocompleteOriginInputChange"
                    />
                    <div
                      v-if="
                        Fields.Routes[CurrentRoute].OriginAddress.Street.trim()
                          .length < 1
                      "
                      class="v-text-field__details pt-2 pl-0 pb-0 m-0"
                    >
                      <div
                        class="v-messages theme--light error--text"
                        role="alert"
                      >
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message">
                            {{ $t("VALIDATION.REQUIRED_FIELD") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-3">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.POSTAL_CODE_2") }}
                    </label>
                    <v-text-field
                      class="form-control"
                      v-model="
                        Fields.Routes[CurrentRoute].OriginAddress.PostalCode
                      "
                      required
                      :rules="[rules.required]"
                      @keyup="onPostalCodeChanged('OriginAddress')"
                    />
                  </div>

                  <div class="form-group col-lg-6">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.CITY") }}
                    </label>
                    <v-text-field
                      class="form-control"
                      v-model="Fields.Routes[CurrentRoute].OriginAddress.City"
                      required
                      :rules="[rules.required]"
                      @keyup="unsetFrequentAddressID('OriginAddress')"
                    />
                  </div>

                  <div class="form-group col-lg-6">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.PROVINCE") }}
                    </label>
                    <select
                      class="form-control select-down"
                      v-model="
                        Fields.Routes[CurrentRoute].OriginAddress.ProvinceID
                      "
                      required
                      @change="unsetFrequentAddressID('OriginAddress')"
                    >
                      <option
                        v-for="p in ProvinceOptions"
                        :key="'OriginAddress_' + p.ProvinceID"
                        :value="p.ProvinceID"
                        class="text-primary"
                      >
                        {{ p.Name }}
                      </option>
                    </select>
                  </div>

                  <div
                    v-if="isAuthenticated && currentUserCompanyIsPremium"
                    class="form-group col-lg-12"
                  >
                    <button
                      class="btn btn-pill btn-white button-filter button-form-primary px-3 py-1 mr-3"
                      @click="openFrequentAddressDialog('origin', $event)"
                    >
                      <inline-svg
                        src="/media/icons/book-primary.svg"
                        class="mr-3"
                      />
                      {{ $t("PRICE_SEARCH.STEP1.IMPORT_FREQUENT_ADDRESS") }}
                    </button>

                    <button
                      class="btn btn-pill btn-white button-filter button-form-primary px-3 py-1"
                      @click="openTenderRouteDialog"
                    >
                      <inline-svg
                        src="/media/icons/book-primary.svg"
                        class="mr-3"
                      />
                      {{ $t("PRICE_SEARCH.STEP1.IMPORT_TENDER_ROUTE") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--end::OriginAddress-->

              <!--begin::DestinationAddress-->
              <div class="form-group col-lg-6 px-9 mb-0">
                <div class="row">
                  <div class="col-lg-12">
                    <label>
                      <inline-svg
                        src="/media/icons/pin-1-primary.svg"
                        class="mr-3"
                      />
                      {{ $t("PRICE_SEARCH.STEP1.DESTINATION") }}
                    </label>
                  </div>

                  <div class="form-group col-lg-9">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.FULL_ADDRESS_2") }}
                    </label>
                    <VueGoogleAutocomplete
                      ref="autocompleteDestinationAddress"
                      id="autocompleteDestinationAddress"
                      country="es"
                      :fields="['address_components', 'geometry', 'id', 'name']"
                      v-model="
                        Fields.Routes[CurrentRoute].DestinationAddress.Street
                      "
                      :rules="[rules.required]"
                      required
                      classname="form-control"
                      placeholder=""
                      @placechanged="onAutocompleteDestinationPlaceChanged"
                      @inputChange="onAutocompleteDestinationInputChange"
                    />
                    <div
                      v-if="
                        Fields.Routes[
                          CurrentRoute
                        ].DestinationAddress.Street.trim().length < 1
                      "
                      class="v-text-field__details pt-2 pl-0 pb-0 m-0"
                    >
                      <div
                        class="v-messages theme--light error--text"
                        role="alert"
                      >
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message">
                            {{ $t("VALIDATION.REQUIRED_FIELD") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-3">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.POSTAL_CODE_2") }}
                    </label>
                    <v-text-field
                      class="form-control"
                      v-model="
                        Fields.Routes[CurrentRoute].DestinationAddress
                          .PostalCode
                      "
                      required
                      :rules="[rules.required]"
                      @keyup="onPostalCodeChanged('DestinationAddress')"
                    />
                  </div>

                  <div class="form-group col-lg-6">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.CITY") }}
                    </label>
                    <v-text-field
                      class="form-control"
                      v-model="
                        Fields.Routes[CurrentRoute].DestinationAddress.City
                      "
                      required
                      :rules="[rules.required]"
                      @keyup="unsetFrequentAddressID('DestinationAddress')"
                    />
                  </div>

                  <div class="form-group col-lg-6">
                    <label>
                      {{ $t("FREQUENT_ADDRESSES.PROVINCE") }}
                    </label>
                    <select
                      class="form-control select-down"
                      v-model="
                        Fields.Routes[CurrentRoute].DestinationAddress
                          .ProvinceID
                      "
                      required
                      @change="unsetFrequentAddressID('DestinationAddress')"
                    >
                      <option
                        v-for="p in ProvinceOptions"
                        :key="'DestinationAddress_' + p.ProvinceID"
                        :value="p.ProvinceID"
                        class="text-primary"
                      >
                        {{ p.Name }}
                      </option>
                    </select>
                  </div>

                  <div
                    v-if="isAuthenticated && currentUserCompanyIsPremium"
                    class="form-group col-lg-12"
                  >
                    <button
                      class="btn btn-pill btn-white button-filter button-form-primary px-3 py-1"
                      @click="openFrequentAddressDialog('destination', $event)"
                    >
                      <inline-svg
                        src="/media/icons/book-primary.svg"
                        class="mr-3"
                      />
                      {{ $t("PRICE_SEARCH.STEP1.IMPORT_FREQUENT_ADDRESS") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--end::DestinationAddress-->
            </div>

            <!--begin::FrequentAddress dialog-->
            <v-dialog
              v-model="FrequentAddressDialog.Show"
              transition="dialog-bottom-transition"
              max-width="700px"
            >
              <v-card>
                <div class="bg-primary text-white font-size-h5 py-3 px-7">
                  {{
                    $t(
                      "PRICE_SEARCH.STEP1." +
                        FrequentAddressDialog.WhichSide.toUpperCase()
                    ) +
                      ": " +
                      $t("PRICE_SEARCH.STEP1.IMPORT_FREQUENT_ADDRESS")
                  }}
                </div>

                <v-card-text class="pt-6 pb-3">
                  <div class="row mb-6 px-3">
                    <v-text-field
                      class="form-control search-text"
                      v-model="FrequentAddressDialog.Search"
                      :placeholder="$t('GENERIC.SEARCH') + '...'"
                    >
                      <template v-slot:prepend-inner>
                        <inline-svg
                          src="/media/icons/search-alt-1-primary.svg"
                        />
                      </template>
                    </v-text-field>
                  </div>

                  <div class="row font-size-base">
                    <div
                      class="col-lg-12 mb-3 text-danger"
                      v-if="filterFrequentAddressOptions().length === 0"
                    >
                      {{ $t("GENERIC.NOT_FOUND") }}
                    </div>

                    <div
                      class="col-lg-12 mb-3"
                      v-else
                      v-for="fa in filterFrequentAddressOptions()"
                      :key="fa.AddressID"
                      style="cursor: pointer"
                      @click="saveFrequentAddressDialog(fa.AddressID)"
                    >
                      <inline-svg
                        src="/media/icons/chevron-left-primary.svg"
                        class="mr-3"
                      />
                      <strong class="font-size-h4 mr-1">{{ fa.Nick }}</strong>
                      {{ fa.Street + ". " + fa.PostalCode + " - " + fa.City }}
                    </div>
                  </div>

                  <div class="row button-row py-3">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary"
                      @click="FrequentAddressDialog.Show = false"
                    >
                      {{ $t("GENERIC.CANCEL") }}
                    </button>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!--end::FrequentAddress dialog-->

            <!--begin::TenderRoute dialog-->
            <v-dialog
              v-model="TenderRouteDialog.Show"
              transition="dialog-bottom-transition"
              max-width="700px"
            >
              <v-card>
                <div class="bg-primary text-white font-size-h5 py-3 px-7">
                  {{ $t("PRICE_SEARCH.STEP1.IMPORT_TENDER_ROUTE") }}
                </div>

                <v-card-text class="pt-6 pb-3">
                  <div class="row mb-6 px-3">
                    <v-text-field
                      class="form-control search-text"
                      v-model="TenderRouteDialog.Search"
                      :placeholder="$t('GENERIC.SEARCH') + '...'"
                    >
                      <template v-slot:prepend-inner>
                        <inline-svg
                          src="/media/icons/search-alt-1-primary.svg"
                        />
                      </template>
                    </v-text-field>
                  </div>

                  <div class="row font-size-base">
                    <div
                      class="col-lg-12 mb-3 text-danger"
                      v-if="filterTenderRouteOptions().length === 0"
                    >
                      {{ $t("GENERIC.NOT_FOUND") }}
                    </div>

                    <div
                      class="col-lg-12 mb-3"
                      v-else
                      v-for="tr in filterTenderRouteOptions()"
                      :key="tr.TenderRouteID"
                      style="cursor: pointer"
                      @click="saveTenderRouteDialog(tr.TenderRouteID)"
                    >
                      <inline-svg
                        src="/media/icons/chevron-left-primary.svg"
                        class="mr-3"
                      />
                      <strong class="font-size-h4 mr-1">
                        {{ getProvinceName(tr.OriginProvinceID) }}
                      </strong>
                      {{
                        tr.OriginAddress
                          ? " (" + tr.OriginAddress.Nick + ")"
                          : ""
                      }}
                      <strong class="font-size-h4 mr-1">
                        &rBarr;
                        {{ getProvinceName(tr.DestinationProvinceID) }}
                      </strong>
                      {{
                        tr.DestinationAddress
                          ? " (" + tr.DestinationAddress.Nick + ")"
                          : ""
                      }}
                    </div>
                  </div>

                  <div class="row button-row py-3">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary"
                      @click="TenderRouteDialog.Show = false"
                    >
                      {{ $t("GENERIC.CANCEL") }}
                    </button>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!--end::FrequentAddress dialog-->

            <div
              v-for="whichLeg in Fields.Routes[CurrentRoute].Back.Enabled
                ? ['There', 'Back']
                : ['There']"
              :key="whichLeg"
            >
              <div class="row" v-if="Fields.Routes[CurrentRoute].Back.Enabled">
                <div class="form-group col-lg-12 px-9 mb-0">
                  <label>
                    {{
                      $t(
                        "PRICE_SEARCH.STEP1." + whichLeg.toUpperCase()
                      ).toUpperCase()
                    }}
                  </label>
                </div>
              </div>

              <div class="row">
                <!--begin::Vehicles-->
                <div class="form-group col-lg-6 px-9">
                  <div
                    v-if="whichLeg === 'Back'"
                    class="col-lg-12 form-group pl-0"
                  >
                    <v-checkbox
                      v-model="Fields.Routes[CurrentRoute].Back.SameVehicles"
                      style="margin-bottom: -1em"
                      @click="onClickBackSameVehicles"
                    >
                      <template v-slot:label>
                        <div class="pt-1 ml-3 text-dark">
                          {{ $t("PRICE_SEARCH.STEP1.SAME_VEHICLES") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>

                  <div
                    v-if="
                      whichLeg === 'There' ||
                        !Fields.Routes[CurrentRoute].Back.SameVehicles
                    "
                  >
                    <div
                      v-if="
                        Fields.Routes[CurrentRoute][whichLeg].Vehicles
                          .length === 0
                      "
                      class="mb-3"
                    >
                      <label>
                        <inline-svg
                          src="/media/icons/car-primary.svg"
                          class="mr-3"
                        />
                        {{ $t("PRICE_SEARCH.STEP1.VEHICLE") }} 1
                      </label>
                      <div
                        class="v-messages theme--light error--text"
                        role="alert"
                      >
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message">
                            {{ $t("VALIDATION.REQUIRED_FIELD") }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else>
                      <div
                        v-for="(vehicle, index) in Fields.Routes[CurrentRoute][
                          whichLeg
                        ].Vehicles"
                        :key="whichLeg + '_' + index"
                      >
                        <label>
                          <inline-svg
                            src="/media/icons/car-primary.svg"
                            class="mr-3"
                          />
                          {{ $t("PRICE_SEARCH.STEP1.VEHICLE") }}
                          {{ index + 1 }}
                        </label>
                        <p class="mb-0">
                          {{
                            "x" +
                              vehicle.Quantity +
                              ", " +
                              vehicle.Brand +
                              ", " +
                              vehicle.Model +
                              ", " +
                              vehicle.Version +
                              ", " +
                              vehicle.Year +
                              ". " +
                              getVehicleTypeName(vehicle.VehicleTypeID) +
                              ", " +
                              getVehicleStatusName(vehicle.VehicleStatusID)
                          }}
                        </p>
                        <p v-if="vehicle.VehicleID" class="mt-0">
                          {{ $t("MY_VEHICLES.PLATE") }}:
                          <strong>{{ vehicle.Plate }}</strong>
                        </p>

                        <div class="mt-3 mb-9">
                          <span
                            v-if="!vehicle.VehicleID"
                            class="action-icon mr-4"
                            @click="openVehicleDialog(whichLeg, index, $event)"
                          >
                            <inline-svg
                              src="/media/icons/edit-alt-2-primary.svg"
                            />
                          </span>
                          <span
                            class="action-icon"
                            @click="deleteVehicle(whichLeg, index)"
                          >
                            <inline-svg
                              src="/media/icons/delete-alt-2-primary.svg"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <button
                        class="btn button-filter btn-pill btn-primary px-6 py-1"
                        @click="openVehicleDialog(whichLeg, -1, $event)"
                      >
                        <inline-svg src="/media/icons/add.svg" class="mr-3" />
                        {{ $t("PRICE_SEARCH.STEP1.VEHICLE_ADD") }}
                      </button>

                      <button
                        v-if="isAuthenticated && currentUserCompanyIsPremium"
                        class="btn btn-pill btn-white button-filter button-form-primary px-6 py-1 ml-6"
                        @click="openMyVehicleDialog(whichLeg, $event)"
                      >
                        <inline-svg
                          src="/media/icons/book-primary.svg"
                          class="mr-3"
                        />
                        {{ $t("PRICE_SEARCH.STEP1.IMPORT_MY_VEHICLE") }}
                      </button>
                    </div>
                  </div>
                </div>
                <!--end::Vehicles-->

                <!--begin::DesiredDestinationDate-->
                <div class="form-group col-lg-6 px-9">
                  <label>
                    <inline-svg
                      src="/media/icons/calendar-add-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("PRICE_SEARCH.STEP1.DESTINATION_DATE_DESIRED") }}
                  </label>
                  <select
                    class="form-control select-down"
                    v-model="
                      Fields.Routes[CurrentRoute][whichLeg]
                        .DestinationDateDesiredText
                    "
                    required
                    @click.stop="
                      onChangeDestinationDateDesired(whichLeg, $event)
                    "
                  >
                    <option value="ONE_WEEK" class="text-primary">
                      {{ $t("PRICE_SEARCH.STEP1.ONE_WEEK") }}
                    </option>
                    <option value="TWO_WEEKS" class="text-primary">
                      {{ $t("PRICE_SEARCH.STEP1.TWO_WEEKS") }}
                    </option>
                    <option
                      value="SPECIFIC_DATE"
                      class="text-primary"
                      @click="openDestinationDateDesiredDialog(whichLeg)"
                    >
                      {{ $t("PRICE_SEARCH.STEP1.SPECIFIC_DATE") }}
                      <span
                        v-if="
                          Fields.Routes[CurrentRoute][whichLeg]
                            .DestinationDateDesiredText === 'SPECIFIC_DATE'
                        "
                      >
                        ({{
                          Fields.Routes[CurrentRoute][whichLeg]
                            .DestinationDateDesiredFromFormatted
                        }})
                      </span>
                    </option>
                    <option value="SPECIFIC_RANGE" class="text-primary">
                      {{ $t("PRICE_SEARCH.STEP1.SPECIFIC_RANGE") }}
                      <span
                        v-if="
                          Fields.Routes[CurrentRoute][whichLeg]
                            .DestinationDateDesiredText === 'SPECIFIC_RANGE'
                        "
                      >
                        ({{
                          Fields.Routes[CurrentRoute][whichLeg]
                            .DestinationDateDesiredFromFormatted +
                            " - " +
                            Fields.Routes[CurrentRoute][whichLeg]
                              .DestinationDateDesiredToFormatted
                        }})
                      </span>
                    </option>
                    <option value="FLEXIBLE" class="text-primary">
                      {{ $t("PRICE_SEARCH.STEP1.FLEXIBLE") }}
                    </option>
                  </select>
                  <div class="mt-2">
                    <inline-svg
                      class="mr-1"
                      src="/media/icons/help-alt-5-primary.svg"
                    />
                    {{ $t("PRICE_SEARCH.STEP1.FLEXIBLE_CHEAPER") }}
                  </div>
                </div>
                <!--end::DesiredDestinationDate-->
              </div>

              <!--begin::Vehicle dialog-->
              <v-dialog
                v-model="VehicleDialog.Show"
                transition="dialog-bottom-transition"
                max-width="700px"
              >
                <v-card>
                  <div class="bg-primary text-white font-size-h5 py-3 px-7">
                    <span v-if="Fields.Routes[CurrentRoute].Back.Enabled">
                      {{
                        $t(
                          "PRICE_SEARCH.STEP1." +
                            VehicleDialog.WhichLeg.toUpperCase()
                        )
                      }}:
                    </span>
                    <span v-if="VehicleDialog.WhichVehicle === -1">
                      {{ $t("PRICE_SEARCH.STEP1.VEHICLE_ADD") }}
                    </span>
                    <span v-else>
                      {{ $t("PRICE_SEARCH.STEP1.VEHICLE") }}
                      {{ VehicleDialog.WhichVehicle + 1 }}
                    </span>
                  </div>

                  <v-card-text class="pt-6 pb-3">
                    <VehicleModelPicker
                      :initial-model.sync="VehicleDialog.ModelData"
                      :show-type-id="true"
                      :show-model-id="false"
                      :fields-per-row="2"
                      :trigger-validate-picker="false"
                      @change-model-data="VehicleDialog.ModelData = $event"
                    />

                    <div class="row mt-0">
                      <div class="col-lg-8 form-group py-0">
                        <label>
                          {{ $t("MY_VEHICLES.VEHICLE_STATUS") }}
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <inline-svg
                                v-bind="attrs"
                                v-on="on"
                                class="ml-3"
                                src="/media/icons/help-alt-5-primary.svg"
                              />
                            </template>
                            <span>
                              {{ $t("PRICE_SEARCH.STEP1.VEHICLE_STATUS_INFO") }}
                            </span>
                          </v-tooltip>
                        </label>
                        <select
                          class="form-control select-down"
                          v-model="VehicleDialog.StatusID"
                          required
                          :rules="[rules.required]"
                        >
                          <option
                            v-for="vs in VehicleStatusOptions"
                            :key="vs.VehicleStatusID"
                            :value="vs.VehicleStatusID"
                            class="text-primary"
                          >
                            {{ vs.Name }}
                          </option>
                        </select>
                      </div>

                      <div class="col-lg-4 form-group py-0">
                        <label>
                          {{ $t("PRICE_SEARCH.STEP1.QUANTITY") }}
                          <inline-svg
                            src="/media/icons/help-alt-5-primary.svg"
                            style="visibility: hidden"
                          />
                        </label>
                        <v-text-field
                          class="form-control"
                          v-model="VehicleDialog.Quantity"
                          type="number"
                          min="1"
                          step="1"
                          required
                          :rules="[rules.required, rules.min1]"
                        />
                      </div>
                    </div>

                    <div class="row button-row py-3">
                      <button
                        class="btn btn-pill btn-white button-form button-form-primary mr-9"
                        @click="VehicleDialog.Show = false"
                      >
                        {{ $t("GENERIC.CANCEL") }}
                      </button>
                      <button
                        class="btn btn-pill btn-tertiary button-form"
                        @click="saveVehicleDialog"
                      >
                        {{ $t("GENERIC.OK") }}
                      </button>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!--end::Vehicle dialog-->

              <!--begin::MyVehicle dialog-->
              <v-dialog
                v-model="MyVehicleDialog.Show"
                transition="dialog-bottom-transition"
                max-width="700px"
              >
                <v-card>
                  <div class="bg-primary text-white font-size-h5 py-3 px-7">
                    {{ $t("PRICE_SEARCH.STEP1.IMPORT_MY_VEHICLE") }}
                  </div>

                  <v-card-text class="pt-6 pb-3">
                    <div class="row mb-6 px-3">
                      <v-text-field
                        class="form-control search-text"
                        v-model="MyVehicleDialog.Search"
                        :placeholder="$t('GENERIC.SEARCH') + '...'"
                      >
                        <template v-slot:prepend-inner>
                          <inline-svg
                            src="/media/icons/search-alt-1-primary.svg"
                          />
                        </template>
                      </v-text-field>
                    </div>

                    <div class="row font-size-base">
                      <div
                        class="col-lg-12 mb-3 text-danger"
                        v-if="filterMyVehicleOptions().length === 0"
                      >
                        {{ $t("GENERIC.NOT_FOUND") }}
                      </div>

                      <div
                        class="col-lg-12 mb-3"
                        v-else
                        v-for="mv in filterMyVehicleOptions()"
                        :key="mv.VehicleID"
                        style="cursor: pointer"
                        @click="saveMyVehicleDialog(mv.VehicleID)"
                      >
                        <inline-svg
                          src="/media/icons/chevron-left-primary.svg"
                          class="mr-3"
                        />
                        <strong class="font-size-h4 mr-1">
                          {{ mv.Plate }}
                        </strong>
                        {{
                          mv.VehicleModel.Brand +
                            ", " +
                            mv.VehicleModel.Model +
                            ", " +
                            mv.VehicleModel.Version +
                            ", " +
                            mv.Year +
                            ". " +
                            getVehicleTypeName(mv.VehicleModel.VehicleTypeID) +
                            ", " +
                            getVehicleStatusName(mv.VehicleStatusID)
                        }}
                      </div>
                    </div>

                    <div class="row button-row py-3">
                      <button
                        class="btn btn-pill btn-white button-form button-form-primary"
                        @click="MyVehicleDialog.Show = false"
                      >
                        {{ $t("GENERIC.CANCEL") }}
                      </button>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!--end::MyVehicle dialog-->

              <!--begin::DestinationDateDesired1 dialog-->
              <v-dialog
                v-model="DestinationDateDesiredDialog.Show"
                :return-value="DestinationDateDesiredDialog.Value"
                persistent
                width="320px"
              >
                <v-date-picker
                  v-model="DestinationDateDesiredDialog.Value"
                  color="tertiary"
                  scrollable
                  :locale="DateLocale"
                  :first-day-of-week="DateFirstDayOfWeek"
                  :min="Today"
                  :max="OneYear"
                >
                  <div class="row button-row">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                      style="min-width: 125px"
                      @click="cancelDestinationDateDesiredDialog"
                    >
                      {{ $t("GENERIC.CANCEL") }}
                    </button>
                    <button
                      class="btn btn-pill btn-tertiary button-form"
                      style="min-width: 125px"
                      @click="saveDestinationDateDesiredDialog"
                    >
                      {{ $t("GENERIC.OK") }}
                    </button>
                  </div>
                </v-date-picker>
              </v-dialog>
              <!--end::DestinationDateDesired1 dialog-->

              <!--begin::DestinationDateDesiredRange dialog-->
              <v-dialog
                v-model="DestinationDateDesiredRangeDialog.Show"
                :return-value="DestinationDateDesiredRangeDialog.Value"
                persistent
                width="320px"
              >
                <v-date-picker
                  id="DestinationDateDesiredRangeDialog"
                  v-model="DestinationDateDesiredRangeDialog.Value"
                  color="tertiary"
                  scrollable
                  range
                  :locale="DateLocale"
                  :first-day-of-week="DateFirstDayOfWeek"
                  :min="Today"
                  :max="OneYear"
                >
                  <div class="row button-row">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                      style="min-width: 125px"
                      @click="cancelDestinationDateDesiredRangeDialog"
                    >
                      {{ $t("GENERIC.CANCEL") }}
                    </button>
                    <button
                      class="btn btn-pill btn-tertiary button-form"
                      style="min-width: 125px"
                      @click="saveDestinationDateDesiredRangeDialog"
                    >
                      {{ $t("GENERIC.OK") }}
                    </button>
                  </div>
                </v-date-picker>
              </v-dialog>
              <!--end::DestinationDateDesiredRange dialog-->
            </div>

            <!--begin::Submit button-->
            <div class="row button-row justify-content-start pt-6 pb-0 px-9">
              <button
                class="btn btn-pill btn-tertiary button-form"
                :disabled="!formStep1Valid || Submitting"
                @click="onSubmitForm"
              >
                <inline-svg src="/media/icons/search-alt-1.svg" class="mr-3" />
                {{ $t("GENERIC.SEARCH") }}
              </button>
            </div>
            <!--end::Submit button-->
          </v-form>
        </div>
      </div>
    </div>
  </div>
  <!--end::PriceSearch step 1 form-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import VueGoogleAutocomplete from "vue-google-autocomplete";
import VehicleModelPicker from "@/view/pages/manager/vehicles/VehicleModelPicker.vue";

import AddressService from "@/core/services/api/v2/address.service";
import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import TenderRouteService from "@/core/services/api/v2/tenderroute.service";
import VehicleService from "@/core/services/api/v2/vehicle.service";
import VehicleStatusService from "@/core/services/api/v2/vehiclestatus.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "PriceSearchStep1Form",

  components: {
    VueGoogleAutocomplete,
    VehicleModelPicker
  },

  data() {
    return {
      ProvinceOptions: [],
      FrequentAddressDialog: {
        List: [],
        Show: false,
        WhichSide: "origin",
        Search: ""
      },
      TenderRouteDialog: {
        List: [],
        Show: false,
        Search: ""
      },
      VehicleStatusOptions: [],
      VehicleTypeOptions: [],
      VehicleDialog: {
        Show: false,
        WhichLeg: "There",
        WhichVehicle: -1,
        ModelData: null,
        StatusID: 1,
        Quantity: 1
      },
      MyVehicleDialog: {
        Show: false,
        WhichLeg: "There",
        List: [],
        Search: ""
      },
      DestinationDateDesiredDialog: {
        Show: false,
        WhichLeg: "",
        Value: ""
      },
      DestinationDateDesiredRangeDialog: {
        Show: false,
        WhichLeg: "",
        Value: []
      },
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      Today: "",
      OneYear: "",
      CurrentRoute: 0,
      Submitting: false,
      Fields: {
        Routes: []
      },
      EmptyRoute: {
        OriginAddress: {
          AddressID: "",
          Nick: "",
          TradeName: "",
          Street: "",
          PostalCode: "",
          City: "",
          ProvinceID: 0
        },
        DestinationAddress: {
          AddressID: "",
          Nick: "",
          TradeName: "",
          Street: "",
          PostalCode: "",
          City: "",
          ProvinceID: 0
        },
        There: {
          Vehicles: [],
          DestinationDateDesiredText: "FLEXIBLE",
          DestinationDateDesiredFrom: "",
          DestinationDateDesiredTo: "",
          DestinationDateDesiredFromFormatted: "",
          DestinationDateDesiredToFormatted: ""
        },
        Back: {
          Enabled: false,
          EnabledString: "false",
          SameVehicles: true,
          Vehicles: [],
          DestinationDateDesiredText: "FLEXIBLE",
          DestinationDateDesiredFrom: "",
          DestinationDateDesiredTo: "",
          DestinationDateDesiredFromFormatted: "",
          DestinationDateDesiredToFormatted: ""
        }
      },
      EmptyVehicleModel: {
        VehicleModelID: 0,
        VehicleType: null,
        Brand: "",
        Model: "",
        Version: "",
        Year: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        min1: value =>
          parseInt(value) >= 1 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: 1 })
      }
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserCompanyIsPremium"]),
    formStep1Valid() {
      let valid = true;
      if (!this.Fields.Routes || this.Fields.Routes.length === 0) {
        valid = false;
      } else {
        // For each route...
        this.Fields.Routes.forEach(r => {
          // Not empty adresses
          if (
            !r.OriginAddress.Street ||
            r.OriginAddress.Street.trim().length === 0 ||
            !r.OriginAddress.PostalCode ||
            r.OriginAddress.PostalCode.trim().length === 0 ||
            !r.OriginAddress.City ||
            r.OriginAddress.City.trim().length === 0 ||
            r.OriginAddress.ProvinceID < 1
          ) {
            valid = false;
          } else if (
            !r.DestinationAddress.Street ||
            r.DestinationAddress.Street.trim().length === 0 ||
            !r.DestinationAddress.PostalCode ||
            r.DestinationAddress.PostalCode.trim().length === 0 ||
            !r.DestinationAddress.City ||
            r.DestinationAddress.City.trim().length === 0 ||
            r.DestinationAddress.ProvinceID < 1
          ) {
            valid = false;
          }

          // At least one vehicle, There
          else if (r.There.Vehicles.length === 0) {
            valid = false;
          }
          // and Back (if enabled and different)
          else if (
            r.Back.Enabled &&
            !r.Back.SameVehicles &&
            r.Back.Vehicles.length === 0
          ) {
            valid = false;
          }
        });
      }

      return valid;
    }
  },

  created() {
    this.Today = moment().format(this.DateFormatEN);
    this.OneYear = moment()
      .add(1, "years")
      .format(this.DateFormatEN);

    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }

    this.loadProvinceOptions();
    this.loadVehicleStatusOptions();
    this.loadVehicleTypeNames();
    this.Fields.Routes[0] = Object.assign({}, this.EmptyRoute);
  },

  mounted() {
    // Can start a search from several places:
    //   a) one of "My Vehicles"
    //   b) one of "My Frequent Addresses" (taken as origin)
    //   c) one of "My Tender Routes"
    //   d) back to step1 from step2 or a totally new search
    let myVehicleID = parseInt(this.$route.query.VehicleID);
    let myAddressID = parseInt(this.$route.query.AddressID);
    let myTenderRouteID = parseInt(this.$route.query.TenderRouteID);

    if (!isNaN(myVehicleID) && myVehicleID > 0) {
      this.ensureOneEmptyRoute();

      // Re-check that vehicle belongs to current user, is active, and not travelling
      VehicleService.getProfile(myVehicleID).then(response => {
        if (response.Plate && response.Active && !response.Travelling) {
          this.Fields.Routes[0].There.Vehicles.push({
            VehicleID: response.VehicleID,
            Plate: response.Plate,
            Brand: response.VehicleModel.Brand,
            Model: response.VehicleModel.Model,
            Version: response.VehicleModel.Version,
            Year: response.VehicleModel.Year,
            VehicleModelID: response.VehicleModel.VehicleModelID,
            VehicleTypeID: response.VehicleModel.VehicleType.VehicleTypeID,
            VehicleStatusID: response.VehicleStatus.VehicleStatusID,
            Quantity: 1
          });

          PriceSearchService.cleanCachedLists();
        }
      });
    } else if (!isNaN(myAddressID) && myAddressID > 0) {
      this.ensureOneEmptyRoute();

      // Simulate he picked it from FrequentAddressDialog
      AddressService.listAll(true).then(response => {
        this.FrequentAddressDialog.List = response;
        this.FrequentAddressDialog.WhichSide = "origin";
        this.saveFrequentAddressDialog(myAddressID);

        PriceSearchService.cleanCachedLists();
      });
    } else if (!isNaN(myTenderRouteID) && myTenderRouteID > 0) {
      this.ensureOneEmptyRoute();

      // Simulate he picked it from TenderRouteDialog
      TenderRouteService.listAll(true).then(response => {
        this.TenderRouteDialog.List = response;
        this.saveTenderRouteDialog(myTenderRouteID);

        PriceSearchService.cleanCachedLists();
      });
    } else {
      this.Fields = PriceSearchService.getCachedRoutes();
      this.ensureOneEmptyRoute();
    }

    // Force "required field" messages
    this.$refs.formStep1.validate();
  },

  methods: {
    ensureOneEmptyRoute() {
      if (this.Fields.Routes.length === 0) {
        this.Fields.Routes[0] = Object.assign({}, this.EmptyRoute);
      }
    },

    loadProvinceOptions() {
      this.ProvinceOptions = [];
      ProvinceService.listAll().then(response => {
        this.ProvinceOptions = response;
      });
    },

    getProvinceName(pID) {
      let name = "";

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        if (maybeProvince.ProvinceID === pID) {
          name = maybeProvince.Name;
          break;
        }
      }

      return name;
    },

    loadVehicleStatusOptions() {
      this.VehicleStatusOptions = [];
      VehicleStatusService.listAll().then(response => {
        this.VehicleStatusOptions = response;
      });
    },

    getVehicleStatusName(vsID) {
      let name = "";

      for (let s = 0; s < this.VehicleStatusOptions.length; s++) {
        let maybeStatus = this.VehicleStatusOptions[s];
        if (maybeStatus.VehicleStatusID === vsID) {
          name = maybeStatus.Name;
          break;
        }
      }

      return name;
    },

    loadVehicleTypeNames() {
      this.VehicleTypeNames = [];
      VehicleTypeService.listAll().then(response => {
        response.forEach(vt => {
          this.VehicleTypeNames[vt.VehicleTypeID] = vt.Name;
        });
      });
    },

    getVehicleTypeName(vtID) {
      let name = this.VehicleTypeNames[vtID];
      if (!name) {
        name = "";
      }

      return name;
    },

    onAutocompleteOriginPlaceChanged(addressData) {
      this.onAutocompletePlaceChanged("OriginAddress", addressData);
    },
    onAutocompleteDestinationPlaceChanged(addressData) {
      this.onAutocompletePlaceChanged("DestinationAddress", addressData);
    },
    onAutocompletePlaceChanged(whichSide, addressData) {
      let newStreet =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");
      this.Fields.Routes[this.CurrentRoute][whichSide].Street = newStreet;

      this.Fields.Routes[this.CurrentRoute][whichSide].PostalCode =
        addressData.postal_code;

      this.Fields.Routes[this.CurrentRoute][whichSide].City =
        addressData.locality;

      this.onPostalCodeChanged(whichSide);

      // Clean extra values from Street box
      this.$refs[
        "autocomplete" + whichSide
      ].$refs.autocomplete.value = newStreet;
    },

    onAutocompleteOriginInputChange(e) {
      this.onAutocompleteInputChange("OriginAddress", e);
    },
    onAutocompleteDestinationInputChange(e) {
      this.onAutocompleteInputChange("DestinationAddress", e);
    },
    onAutocompleteInputChange(whichSide, e) {
      if (e.newVal.trim().length === 0) {
        this.Fields.Routes[this.CurrentRoute][whichSide].Street = "";
      }

      this.unsetFrequentAddressID(whichSide);
    },

    onPostalCodeChanged(whichSide) {
      // Convert PostalCode to an item in our ProvinceID selector
      // N.B: Province is CRITICAL because it sets the fare,
      // but Google doesn't give it,
      // that's why we have to guess it via PostalCode...
      // and why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(
        this.Fields.Routes[this.CurrentRoute][whichSide].PostalCode
      );
      let newProvinceID = 1;

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this.Fields.Routes[this.CurrentRoute][
        whichSide
      ].ProvinceID = newProvinceID;

      this.unsetFrequentAddressID(whichSide);
    },

    unsetFrequentAddressID(whichSide) {
      this.Fields.Routes[this.CurrentRoute][whichSide].AddressID = "";
    },

    openFrequentAddressDialog(whichSide, event) {
      event.preventDefault();
      event.stopPropagation();

      // Download the list of valid addresses from this user
      this.FrequentAddressDialog.WhichSide = whichSide;
      this.FrequentAddressDialog.Search = "";

      if (this.isAuthenticated && this.currentUserCompanyIsPremium) {
        if (this.FrequentAddressDialog.List.length > 0) {
          this.FrequentAddressDialog.Show = true;
        } else {
          AddressService.listAll(true).then(response => {
            this.FrequentAddressDialog.List = response;
            this.FrequentAddressDialog.Show = true;
          });
        }
      }
    },

    filterFrequentAddressOptions() {
      let otherSide =
        this.FrequentAddressDialog.WhichSide === "origin"
          ? "DestinationAddress"
          : "OriginAddress";

      let otherID = this.Fields.Routes[this.CurrentRoute][otherSide].AddressID;
      let searchText = this.FrequentAddressDialog.Search.trim().toLowerCase();

      return this.FrequentAddressDialog.List.filter(fa => {
        if (fa.AddressID === otherID) {
          return false;
        } else if (searchText.length === 0) {
          return true;
        } else {
          let fullText =
            fa.Nick +
            " " +
            fa.Street +
            " " +
            fa.PostalCode +
            " " +
            fa.City +
            " " +
            this.getProvinceName(fa.ProvinceID);

          return fullText.toLowerCase().includes(searchText);
        }
      });
    },

    saveFrequentAddressDialog(whichAddressID) {
      for (let a = 0; a < this.FrequentAddressDialog.List.length; a++) {
        let fa = this.FrequentAddressDialog.List[a];
        if (fa.AddressID === whichAddressID) {
          let whichSide =
            this.FrequentAddressDialog.WhichSide === "origin"
              ? "OriginAddress"
              : "DestinationAddress";

          this.Fields.Routes[this.CurrentRoute][whichSide].AddressID =
            fa.AddressID;
          this.Fields.Routes[this.CurrentRoute][whichSide].Nick = fa.Nick;
          this.Fields.Routes[this.CurrentRoute][whichSide].TradeName =
            fa.TradeName;
          this.Fields.Routes[this.CurrentRoute][whichSide].Street = fa.Street;
          this.Fields.Routes[this.CurrentRoute][whichSide].PostalCode =
            fa.PostalCode;
          this.Fields.Routes[this.CurrentRoute][whichSide].City = fa.City;
          this.Fields.Routes[this.CurrentRoute][whichSide].ProvinceID =
            fa.ProvinceID;

          break;
        }
      }

      this.FrequentAddressDialog.Show = false;
    },

    openTenderRouteDialog(event) {
      event.preventDefault();
      event.stopPropagation();

      // Download the list of valid tender routes from this user
      this.TenderRouteDialog.Search = "";

      if (this.isAuthenticated && this.currentUserCompanyIsPremium) {
        if (this.TenderRouteDialog.List.length > 0) {
          this.TenderRouteDialog.Show = true;
        } else {
          TenderRouteService.listAll(true).then(response => {
            this.TenderRouteDialog.List = response;
            this.TenderRouteDialog.Show = true;
          });
        }
      }
    },

    filterTenderRouteOptions() {
      let searchText = this.TenderRouteDialog.Search.trim().toLowerCase();

      if (searchText.length === 0) {
        return this.TenderRouteDialog.List;
      } else {
        return this.TenderRouteDialog.List.filter(tr => {
          let fullText = "";

          if (tr.OriginAddress) {
            fullText +=
              tr.OriginAddress.Nick +
              " " +
              tr.OriginAddress.Street +
              " " +
              tr.OriginAddress.PostalCode +
              " " +
              tr.OriginAddress.City +
              " ";
          }
          fullText += this.getProvinceName(tr.OriginProvinceID);

          if (tr.DestinationAddress) {
            fullText +=
              tr.DestinationAddress.Nick +
              " " +
              tr.DestinationAddress.Street +
              " " +
              tr.DestinationAddress.PostalCode +
              " " +
              tr.DestinationAddress.City +
              " ";
          }
          fullText += this.getProvinceName(tr.DestinationProvinceID);

          return fullText.toLowerCase().includes(searchText);
        });
      }
    },

    saveTenderRouteDialog(whichTenderRouteID) {
      for (let t = 0; t < this.TenderRouteDialog.List.length; t++) {
        let tr = this.TenderRouteDialog.List[t];
        if (tr.TenderRouteID === whichTenderRouteID) {
          ["Origin", "Destination"].forEach(side => {
            let whichSide = side + "Address";
            let addressData = tr[whichSide];

            this.Fields.Routes[this.CurrentRoute][
              whichSide
            ].AddressID = addressData ? addressData.AddressID : "";

            this.Fields.Routes[this.CurrentRoute][
              whichSide
            ].Street = addressData ? addressData.Street : "";

            this.Fields.Routes[this.CurrentRoute][
              whichSide
            ].PostalCode = addressData ? addressData.PostalCode : "";

            this.Fields.Routes[this.CurrentRoute][whichSide].City = addressData
              ? addressData.City
              : "";

            this.Fields.Routes[this.CurrentRoute][whichSide].ProvinceID =
              tr[side + "ProvinceID"];
          });

          break;
        }
      }

      this.TenderRouteDialog.Show = false;
    },

    onChangeBackEnabled() {
      this.Fields.Routes[this.CurrentRoute].Back.Enabled =
        this.Fields.Routes[this.CurrentRoute].Back.EnabledString === "true";

      if (!this.Fields.Routes[this.CurrentRoute].Back.Enabled) {
        this.Fields.Routes[this.CurrentRoute].Back.Vehicles = [];
      }
    },

    onClickBackSameVehicles() {
      if (this.Fields.Routes[this.CurrentRoute].Back.SameVehicles) {
        this.Fields.Routes[this.CurrentRoute].Back.Vehicles = [];
      }
    },

    openVehicleDialog(whichLeg, whichVehicle, event) {
      event.preventDefault();
      event.stopPropagation();

      let vehicleData = null;
      let statusID = 1;
      let quantity = 1;

      if (
        whichVehicle > -1 &&
        this.Fields.Routes[this.CurrentRoute] &&
        this.Fields.Routes[this.CurrentRoute][whichLeg] &&
        this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"] &&
        this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"].length >
          whichVehicle
      ) {
        vehicleData = this.Fields.Routes[this.CurrentRoute][whichLeg][
          "Vehicles"
        ][whichVehicle];
        statusID = vehicleData.VehicleStatusID;
        quantity = vehicleData.Quantity;
      }

      if (!vehicleData) {
        vehicleData = this.EmptyVehicleModel;
        whichVehicle = -1;
      }

      this.VehicleDialog.ModelData = Object.assign({}, vehicleData);
      this.VehicleDialog.StatusID = statusID;
      this.VehicleDialog.Quantity = quantity;
      this.VehicleDialog.WhichLeg = whichLeg;
      this.VehicleDialog.WhichVehicle = whichVehicle;
      this.VehicleDialog.Show = true;
    },

    saveVehicleDialog() {
      let whichLeg = this.VehicleDialog.WhichLeg;
      let whichVehicle = this.VehicleDialog.WhichVehicle;
      let newVehicle = {
        Brand: this.VehicleDialog.ModelData.Brand,
        Model: this.VehicleDialog.ModelData.Model,
        Version: this.VehicleDialog.ModelData.Version,
        Year: this.VehicleDialog.ModelData.Year,
        VehicleModelID: parseInt(this.VehicleDialog.ModelData.VehicleModelID),
        VehicleTypeID: parseInt(this.VehicleDialog.ModelData.VehicleTypeID),
        VehicleStatusID: parseInt(this.VehicleDialog.StatusID),
        Quantity: parseInt(this.VehicleDialog.Quantity)
      };

      if (newVehicle.VehicleModelID > 0 && newVehicle.Quantity > 0) {
        // If it's a new line, check it's not repeated,
        // i.e. there's already another line for "those" model and status
        // (but avoid lines imported from "My Vehicles")
        let repeatedPosition = -1;

        if (whichVehicle < 0) {
          let vLength = this.Fields.Routes[this.CurrentRoute][whichLeg][
            "Vehicles"
          ].length;

          for (let index = 0; index < vLength; index++) {
            let oldVehicle = this.Fields.Routes[this.CurrentRoute][whichLeg][
              "Vehicles"
            ][index];

            if (
              !oldVehicle.VehicleID &&
              oldVehicle.VehicleModelID === newVehicle.VehicleModelID &&
              oldVehicle.VehicleStatusID === newVehicle.VehicleStatusID
            ) {
              repeatedPosition = index;
              break;
            }
          }
        }

        if (repeatedPosition > -1) {
          let oldQuantity = parseInt(
            this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"][
              repeatedPosition
            ]["Quantity"]
          );

          this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"][
            repeatedPosition
          ]["Quantity"] = oldQuantity + newVehicle["Quantity"];
        } else if (whichVehicle < 0) {
          this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"].push(
            newVehicle
          );
        } else {
          this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"][
            whichVehicle
          ] = newVehicle;
        }
      }

      this.VehicleDialog.ModelData = null;
      this.VehicleDialog.Show = false;
    },

    deleteVehicle(whichLeg, whichVehicle) {
      this.Fields.Routes[this.CurrentRoute][whichLeg]["Vehicles"].splice(
        whichVehicle,
        1
      );
    },

    openMyVehicleDialog(whichLeg, event) {
      event.preventDefault();
      event.stopPropagation();

      // Download the list of valid vehicles from this user, active and not travelling
      this.MyVehicleDialog.WhichLeg = whichLeg;
      this.MyVehicleDialog.Search = "";

      if (this.isAuthenticated && this.currentUserCompanyIsPremium) {
        if (this.MyVehicleDialog.List.length > 0) {
          this.MyVehicleDialog.Show = true;
        } else {
          VehicleService.search(
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "true",
            "false",
            "Plate",
            false,
            999,
            0,
            false
          ).then(rawResponse => {
            if (rawResponse === 404) {
              this.MyVehicleDialog.List = [];
            } else {
              this.MyVehicleDialog.List = rawResponse.response.Vehicles;
            }

            this.MyVehicleDialog.Show = true;
          });
        }
      }
    },

    filterMyVehicleOptions() {
      // Avoid those already selected on this leg
      let alreadySelected = [];
      this.Fields.Routes[this.CurrentRoute][
        this.MyVehicleDialog.WhichLeg
      ].Vehicles.forEach(av => {
        alreadySelected.push(av.VehicleID);
      });

      // And filter by text
      let searchText = this.MyVehicleDialog.Search.trim().toLowerCase();

      if (alreadySelected.length === 0 && searchText.length === 0) {
        return this.MyVehicleDialog.List;
      } else {
        return this.MyVehicleDialog.List.filter(mv => {
          if (alreadySelected.includes(mv.VehicleID)) {
            return false;
          } else {
            let fullText =
              mv.Plate +
              " " +
              mv.VehicleModel.Brand +
              " " +
              mv.VehicleModel.Model +
              " " +
              mv.VehicleModel.Version +
              " " +
              mv.Year +
              " " +
              this.getVehicleTypeName(mv.VehicleModel.VehicleTypeID) +
              " " +
              this.getVehicleStatusName(mv.VehicleStatusID);

            return fullText.toLowerCase().includes(searchText);
          }
        });
      }
    },

    saveMyVehicleDialog(whichVehicleID) {
      for (let v = 0; v < this.MyVehicleDialog.List.length; v++) {
        let maybeVehicle = this.MyVehicleDialog.List[v];
        if (maybeVehicle.VehicleID === whichVehicleID) {
          this.Fields.Routes[this.CurrentRoute][this.MyVehicleDialog.WhichLeg][
            "Vehicles"
          ].push({
            VehicleID: maybeVehicle.VehicleID,
            Plate: maybeVehicle.Plate,
            Year: maybeVehicle.Year,
            Brand: maybeVehicle.VehicleModel.Brand,
            Model: maybeVehicle.VehicleModel.Model,
            Version: maybeVehicle.VehicleModel.Version,
            VehicleModelID: maybeVehicle.VehicleModel.VehicleModelID,
            VehicleTypeID: maybeVehicle.VehicleModel.VehicleTypeID,
            VehicleStatusID: maybeVehicle.VehicleStatusID,
            Quantity: 1
          });

          break;
        }
      }

      this.MyVehicleDialog.Show = false;
    },

    onChangeDestinationDateDesired(whichLeg, event) {
      event.stopPropagation();
      event.preventDefault();

      if (event.screenX === 0 && event.screenY === 0) {
        // i.e. event=click on option, not open the selector
        let selected = this.Fields.Routes[this.CurrentRoute][whichLeg]
          .DestinationDateDesiredText;

        let from = null;
        let to = null;

        if (selected === "SPECIFIC_DATE") {
          this.openDestinationDateDesiredDialog(whichLeg);
        } else if (selected === "SPECIFIC_RANGE") {
          this.openDestinationDateDesiredRangeDialog(whichLeg);
        } else {
          if (selected === "ONE_WEEK") {
            from = moment();
            to = moment().add(7, "days");
          } else if (selected === "TWO_WEEKS") {
            from = moment();
            to = moment().add(14, "days");
          }

          this.Fields.Routes[this.CurrentRoute][
            whichLeg
          ].DestinationDateDesiredFrom = from
            ? from.format(this.DateFormatEN)
            : "";

          this.Fields.Routes[this.CurrentRoute][
            whichLeg
          ].DestinationDateDesiredTo = to ? to.format(this.DateFormatEN) : "";

          this.Fields.Routes[this.CurrentRoute][
            whichLeg
          ].DestinationDateDesiredFromFormatted = from
            ? from.format(this.DateFormatLang)
            : "";

          this.Fields.Routes[this.CurrentRoute][
            whichLeg
          ].DestinationDateDesiredTo = to ? to.format(this.DateFormatEN) : "";

          this.Fields.Routes[this.CurrentRoute][
            whichLeg
          ].DestinationDateDesiredToFormatted = to
            ? to.format(this.DateFormatLang)
            : "";
        }
      }
    },

    openDestinationDateDesiredDialog(whichLeg) {
      this.DestinationDateDesiredDialog.WhichLeg = whichLeg;
      this.DestinationDateDesiredDialog.Show = true;
    },

    saveDestinationDateDesiredDialog() {
      let whichLeg = this.DestinationDateDesiredDialog.WhichLeg;
      let newDate = this.DestinationDateDesiredDialog.Value;
      let newDateFormatted = moment(newDate).format(this.DateFormatLang);

      this.Fields.Routes[this.CurrentRoute][
        whichLeg
      ].DestinationDateDesiredFrom = newDate;

      this.Fields.Routes[this.CurrentRoute][
        whichLeg
      ].DestinationDateDesiredTo = newDate;

      this.Fields.Routes[this.CurrentRoute][
        whichLeg
      ].DestinationDateDesiredFromFormatted = newDateFormatted;

      this.Fields.Routes[this.CurrentRoute][
        whichLeg
      ].DestinationDateDesiredToFormatted = newDateFormatted;

      this.DestinationDateDesiredDialog.Show = false;
    },

    cancelDestinationDateDesiredDialog() {
      // Reset picker to old date
      let whichLeg = this.DestinationDateDesiredDialog.WhichLeg;

      let oldDate = this.Fields.Routes[this.CurrentRoute][whichLeg]
        .DestinationDateDesiredFrom;
      this.DestinationDateDesiredDialog.Value = oldDate;

      this.DestinationDateDesiredDialog.Show = false;
    },

    openDestinationDateDesiredRangeDialog(whichLeg) {
      this.DestinationDateDesiredRangeDialog.WhichLeg = whichLeg;
      this.DestinationDateDesiredRangeDialog.Show = true;
    },

    saveDestinationDateDesiredRangeDialog() {
      if (this.DestinationDateDesiredRangeDialog.Value.length === 2) {
        let whichLeg = this.DestinationDateDesiredRangeDialog.WhichLeg;

        let newValues = this.DestinationDateDesiredRangeDialog.Value.sort();
        let from = newValues[0];
        let to = newValues[1];

        this.Fields.Routes[this.CurrentRoute][
          whichLeg
        ].DestinationDateDesiredFrom = from;

        this.Fields.Routes[this.CurrentRoute][
          whichLeg
        ].DestinationDateDesiredTo = to;

        this.Fields.Routes[this.CurrentRoute][
          whichLeg
        ].DestinationDateDesiredFromFormatted = moment(from).format(
          this.DateFormatLang
        );

        this.Fields.Routes[this.CurrentRoute][
          whichLeg
        ].DestinationDateDesiredToFormatted = moment(to).format(
          this.DateFormatLang
        );

        // Paranoid: really picked a range, or the same date twice?
        if (from === to) {
          this.Fields.Routes[this.CurrentRoute][
            whichLeg
          ].DestinationDateDesiredText = "SPECIFIC_DATE";
        }
      }

      this.DestinationDateDesiredRangeDialog.Show = false;
    },

    cancelDestinationDateDesiredRangeDialog() {
      // Reset picker to old date
      let whichLeg = this.DestinationDateDesiredRangeDialog.WhichLeg;

      let oldDates = [
        this.Fields.Routes[this.CurrentRoute][whichLeg]
          .DestinationDateDesiredFrom,
        this.Fields.Routes[this.CurrentRoute][whichLeg].DestinationDateDesiredTo
      ];
      this.DestinationDateDesiredRangeDialog.Value = oldDates;

      this.DestinationDateDesiredRangeDialog.Show = false;
    },

    onSubmitForm() {
      if (this.formStep1Valid && this.$refs.formStep1.validate()) {
        // Disable submit button
        this.Submitting = true;

        // Send request:
        PriceSearchService.queryPrices(this.Fields).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 409) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP1.ERROR_409");
            } else if (statusCode === 412) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP1.ERROR_412");
            } else if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            // Go to results screen
            this.$router.push("/pricesearch/step2");
          }

          // Reset submit button
          this.Submitting = false;
        });
      }
    }
  }
};
</script>
