<template>
  <!--begin::PriceSearch step 1-->
  <div>
    <PriceSearchStep1Form />
    <PriceSearchStep1Ads />
  </div>
  <!--end::PriceSearch step 1-->
</template>

<script>
import PriceSearchStep1Ads from "@/view/pages/pricesearch/Step1Ads.vue";
import PriceSearchStep1Form from "@/view/pages/pricesearch/Step1Form.vue";

export default {
  name: "PriceSearchStep1",
  components: {
    PriceSearchStep1Ads,
    PriceSearchStep1Form
  },

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("HEADER.TITLE") + " " + this.$t("HEADER.SEARCH");
    });
  }
};
</script>
