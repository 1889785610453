<template>
  <!--begin::PriceSearch ads-->
  <div>
    <!--begin::Block1-->
    <div class="bg-primary text-white py-9 px-12">
      <div class="row">
        <div class="col-lg-4 d-flex">
          <inline-svg src="/media/landing/pricesearch/plazos.svg" class="pt-3" />
          <div class="pl-3">
            <p class="font-weight-bolder">
              {{ $t("LANDING.PRICE_SEARCH.BLOCK1.STEP1.TITLE") }}
            </p>
            <p>
              {{ $t("LANDING.PRICE_SEARCH.BLOCK1.STEP1.PARAGRAPH") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 d-flex">
          <inline-svg
            src="/media/landing/pricesearch/vehiculo.svg"
            class="pt-3"
          />
          <div class="pl-3">
            <p class="font-weight-bolder">
              {{ $t("LANDING.PRICE_SEARCH.BLOCK1.STEP2.TITLE") }}
            </p>
            <p>
              {{ $t("LANDING.PRICE_SEARCH.BLOCK1.STEP2.PARAGRAPH") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 d-flex">
          <inline-svg src="/media/landing/pricesearch/seguro.svg" class="pt-3" />
          <div class="pl-3">
            <p class="font-weight-bolder">
              {{ $t("LANDING.PRICE_SEARCH.BLOCK1.STEP3.TITLE") }}
            </p>
            <p>
              {{ $t("LANDING.PRICE_SEARCH.BLOCK1.STEP3.PARAGRAPH") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--end::Block11-->

    <LandingContactZone />
  </div>
  <!--end::PriceSearch ads-->
</template>

<!-- Load Landing custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/Landing.scss";
</style>

<script>
import LandingContactZone from "@/view/pages/landing/ContactZone.vue";

export default {
  name: "PriceSearchStep1Ads",

  components: {
    LandingContactZone
  }
};
</script>
